@font-face {
  font-family: morning-star;
  src: url('../assets/Morning\ Star.ttf');
}

@font-face {
  font-family: poppins;
  src: url('../assets/Poppins-ExtraLight.ttf');
}

@font-face {
  font-family: poppins-header;
  src: url('../assets/Poppins-Regular.ttf');
}

* {
  text-align: center;
  margin: 0 auto;
  /* overflow-y: hidden; */
  color: #2f241d;
}

body {
  background-image: url('../assets/backsplash.png');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

a {
  color: #d8572e;
}

a:visited {
  color: #d8572e;
}


.Header {
  background-color: rgb(212, 217, 184, .8);
  height: 100vh;
}

.hero-img {
  width: 33%;
  margin: 5vh auto 20px auto;
}

.Header h1 {
  color: #d8572e;
  font-size: 86px;
  font-family: morning-star;
  padding: 10px;
  margin-bottom: 5vh;
}

.enter-link {
  padding: 20px;
  text-decoration: none;
  font-family: poppins-header;
  font-size: 22px;
  border: 1px solid #FFFCF0;
  border-radius: 6px;
  background-color: rgb(245, 238, 218, .75);
}

.Section {
  min-height: 100vh;
  background-color: rgb(245, 238, 218, .92);
  padding-bottom: 5em;
}

#alternate {
  background-color: rgb(212, 217, 184, .8);
}

.Section h1 {
  color: #d8572e;
  letter-spacing: 3px;
  font-size: 48px;
  padding-top: 1.5em;
  font-family: morning-star;
  font-weight: 100;
}

.Section h2 {
  padding-top: 5px;
  padding-bottom: 20px;
  font-size: 28px;
  font-family: poppins;
}

.Section h3 {
  padding-top: 40px;
  padding-bottom: 0px;
  font-size: 28px;
  font-family: poppins;
}

.flourish {
  width: 20%;
  padding-bottom: 1.5em;
}
.Section p {
  padding-top: 20px;
  width: 75%;
  font-size: 18px;
  font-family: poppins-header;
}

#headshot {
  width: 25%;
  padding: 20px;
}

.gallery_container {
  width: 75%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  align-content: center;
  align-self: center;
}

.gallery_card {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: #FFFCF0;
  margin: 30px auto 30px auto;
  border-radius: 12px;
  border: 1px solid #2f241d;
  -webkit-box-shadow: -15px 15px 0px 0 rgba(0, 0, 0, 0.08);
  box-shadow: -15px 15px 0px 0 rgba(0, 0, 0, 0.08);
}

.gallery_card img {
  width: 350px;
  padding: 5px;
  border-radius: 12px;
}

.gallery_card h3 {
  font-size: 24px;
  color: #FFFCF0;
}

/* Contact Form  */
.content {
  padding: 7rem 0; }

#form-container {
  padding-top: 5em;
}

.contact-message-header {
  font-family: morning-star;
  font-size: 52px;
  color: #d8572e;
}

.contact-message-content {
  color: #2f241d;
  font-size: 18px;
}

.form-welcome {
  color: #d8572e;
  font-family: poppins-header;
}

.form-control {
  border: none;
  border: 1px solid #ccc;
  border-radius: 4px; }
  .form-control:active, .form-control:focus {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #000; }

.col-form-label {
  color: #000; }

.btn {
  color: #fff;
  background-color: #d8572e;
  border: none;
  margin: 40px auto -40px auto;
  border-radius: 4px !important;
  -webkit-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1) !important;
}

.btn:hover {
  color: #fff;
  background-color: #bb4622;
}
  
.btn:active, .btn:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.box {
  padding: 40px;
  background: rgba(212, 217, 184);
  border-radius: 6px;
  border: 1px solid #2f241d;
  -webkit-box-shadow: -20px 20px 0px 0 rgba(0, 0, 0, 0.08);
  box-shadow: -20px 20px 0px 0 rgba(0, 0, 0, 0.08);
}

  label.error {
  font-size: 12px;
  color: red;
}

/* Footer */
.Footer {
  background-color: rgb(212, 217, 184, .8);
  font-family: poppins-header;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-top: 1.5em;
  align-items: center;
}

#f-contact {
  color: #d8572e;
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 900;
  text-align: left;
}

.f-element h5 {
  text-align: left;
  font-size: 18px;
}

.f-hero {
  width: 25%;
}

.f-title {
  color: #d8572e;
  font-size: 24px;
  font-family: morning-star;
}

.Footer h3 {
  font-size: 15px;
}

.dev-logo {
  max-width: 12vw;
  margin: -22px auto -18px auto;
}

.copyright {
  font-size: 14px;
}

.Footer a {
  font-size: 14px;
  font-style: italic;
  font-weight: bold;
  color: #2f241d
}